.topbar {
    /* width: 100%;
    height: 50px;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 999; */

    width: 100%;
    padding: 5px;
    position: sticky;
    top: 0;
    z-index: 999;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    height: 58px;
    box-shadow: var(--box-shadow);
    /* background-color: var(--main-bg); */
    background-color: #E4001C;
    color:#fff
    /* //var(--main-bg); */
}

.topbarWrapper {
    height: 48px;
    padding: 0px 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-weight: bold;
    margin-top: 4px;
    font-size: 24px;
    font-weight: 600;
    /* color: darkblue; */
    cursor: pointer;
    color:var(--txt-color)
}

.topRight {
    display: flex;
    align-items: center;
}

.topbarIconContainer {
    position: relative;
    cursor: pointer;
    margin-left: 20px; 
    display: flex;
    align-items: center;
    font-size: 16px;
}

.topbarIconContainer>i,
.topbarIconContainer>svg {
    font-size: 1.5rem;
}

.topbarIconContainer> i > span {
    font-size: 1.2rem;
    margin-left: 3px;
}
 

.topIconBadge {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topAvatar {
    /* width: 40px; */
    height: 25px;
    /* border-radius: 50%; */
    /* cursor: pointer; */
}
.tabNavigatorBar {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 999;
    height: 40px;
    background-color: #fff;
    color: #fff;
    border-bottom: 1px solid #e0e0e0;
}

.pageTabs {
    text-align: center;
    font-family: 'Diodrum-Medium';
    padding-top: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: inline-flex;
    margin-left: 40px;
}
.pageTab{
    width: max-content;
    margin-right: 25px;
    font-size: 16px;
    cursor: pointer;
}

.activePageTab{
    color: #40A9FF;
    font-family: 'Diodrum-Semibold';
    border-bottom: 4px solid #40A9FF;

}
.imagePromptForm{
    min-height: 65px;
    padding: 12px 40px 12px 40px;
    background-color: #ffffff;
    color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.imagePromptFormTextInput{
    display: inline-flex;
    border-radius: 5px;
    resize: none;
    height: 41px;
    min-height: 41px;
    max-height: 141px;
    flex-grow: 1;
    text-align: left;
    opacity: 1;
}
.imagePromptFormGenerateButton{
    margin-right: 22px;
    height: 41px;
    width: 90px;
    background-color: #FFFFFF !important;
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    opacity: 1;
    font: normal normal normal 14px/22px Diodrum;
    letter-spacing: 0px;


    text-align: center;
}
.imagePromptFormGenerateButton:hover{
    background: #40A9FF 0% 0% no-repeat padding-box;


}
.quantitySelectorButton{
    height: 42px;
    width: 94px;
    font: normal normal normal 14px/17px Diodrum;
    letter-spacing: 0px;
    color: #313131;
    opacity: 1;
    margin: 0;
}
.quantitySelectorButtonText{
    width: 123px;
    padding-top: 14px;
    margin:auto;
}
.quantitySelectorContent {
    display: none;
    position: relative;
    background-color: #f9f9f9;
    min-width: 60px;
    appearance: none;
    bottom: 44px;
    left: 34px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 2px 6px;
    z-index: 1;
  }
  
  .quantitySelectorButton:hover .quantitySelectorContent {
    display: block;
  }

  .ant-dropdown-menu-item-selected {
    background-color: #E6F7FF !important;
    font-family: Diodrum-Semibold !important;
    color: #313131 !important;
  }
.selectedItemsControlBar {
    width: 500px;
    display: flex;
    position: absolute;
    top: 98px;
    right: 0;
    z-index: 999;
    height: 30px;
    background-color: #fff;
    color: #fff;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0px 0px 0px 30px;

}

.selectedItemsButton{
    background-color: unset;
    margin-left: 40px;
    height: 30px;
    border: 0;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.selectedItemsButtonText{
    margin: 0px 0px 0px 8px;
}
.ant-checkbox-wrapper{
    height: 30px !important;
}
.ant-checkbox{
    align-self: center !important;
    transform: translate(0, 0) !important;
}
.oddBox{
    width: 78px;
}
.selectedItemsButton .oddBox{
    width: 78px !important;
}
.p-2{
    display: inline-flex;
    padding: 5px;
    width: 299px;

}
.p-filler{
    display: inline-flex;

    width: 299px;


}
.hstack{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    display: inline-flex;
}

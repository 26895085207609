.card{
    width:290px !important;
    height: 385px;
    background-color: #fff;
    border-radius: 4px 4px 4px 4px;
    justify-content: center;
    position: relative;
}

.loadingCard{
    border-bottom: 1px solid #ededed;
    border-radius: 4px 4px 4px 4px;
    width: 290px;
    height: 290px;
    font-size: 18px;
    padding-top: 122px;
    margin-bottom: 206px;
    text-align: center;

}
.cardText{
    font-size: 14px;
    color: #313131;
    position: absolute;
    top: 304px;
    height: 65px;
    width: 98%;
    padding: 0 14px;
    margin: 0 4px;
    overflow-y: scroll;
    text-align: left;
    opacity: 1;
    letter-spacing: 0;
}
.cardText::-webkit-scrollbar {
    width: 4px;
  }
.cardImage{
    width: 290px;
    height: 290px;
    border-radius: 4px 4px 4px 4px;
    border-bottom: 1px solid #ededed;
}
.checkboxRow{
    position: absolute;
    display: flex;
    top: 8px;
    left: 16px;
    height: 16px;
    width: 16px;
}
.buttonRow{
    top:252px;
    position: absolute;
    left:12px;
    visibility: hidden;

}
.card:hover .buttonRow {
    visibility: unset;
  }
.card:hover {
    box-shadow: 0px 2px 5px 2px #e8e8e8;
}
.cardButton{
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-width: 0px;
    border-radius: 4px;
    margin: 2px;
    border: 1px solid #ededed;
    cursor: pointer;
}
.cardButtonIcon-disabled{
    color: rgba(16, 16, 16, 0.3);
    stroke: rgba(16, 16, 16, 0.3);
}
.cardButtonIcon{
    stroke-width: 00px;
    max-width: 20px;
    max-height: 20px;
    font-size: 20px;
}
.cardButtonIcon:disabled {
    color: #F5F5F5;
    stroke: #F5F5F5;
}
.cardButton:hover{
    background-color: #F5F5F5;
}